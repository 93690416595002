//styles
import "./Index.scss"
//components
import FinalSection from "./FrequentlyQuestions/Index.js";
import GeneralInformations from "./GeneralInformations/Index.js";
import LandingContainer from "./LandingContainer/Index.js";

function SugarDefender() {
  return (
    <>
      {/* landing Section*/}
      <LandingContainer/>
      {/* General Informations Section*/}
      <GeneralInformations/>
      {/* Frequently Questions */}
      <FinalSection/>
    </>
  );
}

export default SugarDefender;
