// styles
import "./Index.scss";
// modules
import IngredientsCards from "../IgredientCards/Index.js";
// assets
import stfRefOne from"../../../assets/Images/refs1.jpg";
import stfRefTwo from"../../../assets/Images/refs2.jpg";

function GeneralInformations() {
  return (
    <div className="geral-informations-container">
        <div className="center-banner-geral-informations-container">
          <div className="scientific-references">
            <h1>
              Scientific <span>References</span>
            </h1>
            <div className="scientific-disclaimer-images">
              <img src={stfRefOne} alt="scientific-reference-one" />
              <img src={stfRefTwo} alt="scientific-reference-two" />
            </div>
          </div>
          <h1>Inside Every Sugar Defender Drop You'll Find:</h1>
          <p>
            8 Carefully-Selected Ingredients That Support Healthy Blood Sugar
            Levels, Including:
          </p>
          <div className="igredient-section">
            <IngredientsCards/>
          </div>
        </div>
      </div>
  );
}

export default GeneralInformations;
