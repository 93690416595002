//styles
import "./App.scss";
//components
import Home from "./components/Home/Index.js";
import PageNotFound from "./components/PageNotFound/Index.js";
import SugarDefender from "./components/SugarDefender/SugarDefender.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "SugarDefender",
    element: <SugarDefender />
  }
])
function App() {
  return (
    //routes
        <RouterProvider router={router}/>
  );
}

export default App;