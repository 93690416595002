// styles
import "./Index.scss";
// components
import Slider from "../Slider/Index";
// assets
import bottleFruit from "../../../assets/Images/1bottle-fruits.png"

function FinalSection() {
  return (
    <div className="frequently-questions-container">
      <div className="center-banner-frequently-questions">
        <div className="questions-placeholder">
          <h2>Frequently Asked Questions</h2>
          <Slider />
        </div>
        <div className="last-offer-placeholder">
          <div className="text-zone">
            <h1>Ready to improve your everyday life?</h1>
            <p>*96% Of Customers Order 6 Bottles (Our Recommended Option)</p>
            <p>60-Day Money Back Guarantee 100% Satisfaction Guaranteed</p>
            <a
              href="https://0a636ttnwern00yl-bohh3iu0r.hop.clickbank.net/?&traffic_source=google&traffic_type=paid"
              target="_blank"
            >
              Order Now
            </a>
          </div>
          <img src={bottleFruit} alt="bottle-fruit"/>
        </div>
      </div>
    </div>
  );
}

export default FinalSection;
