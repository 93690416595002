//styles
import "./Index.scss";
//assets
import sugarDefenderOne from "../../../assets/Images/SUGARDEFENDERx1-500px.png";
import sugarDefenderThree from "../../../assets/Images/SUGARDEFENDERx3-500px.png";
import sugarDefenderSix from "../../../assets/Images/SUGARDEFENDERx6-500px.png";
import cardImage from "../../../assets/Images/cards.png";
import moneyBackTrigger from "../../../assets/Images/money-back.png";
import signature from "../../../assets/Images/signature.png"

function BuyingCards() {
  const promotionCards = [
    {
      header: "Try One",
      supplyDays: "30 Days Supply",
      image: sugarDefenderOne,
      value: "69",
      saving: "110",
      freeGoods: "",
      total: "179",
      totalWithDiscount: "69",
      shippingMode: "+ SHIPPING",
    },
    {
      header: "Best Value",
      supplyDays: "180 Days Supply",
      image: sugarDefenderThree,
      value: "49",
      saving: "780",
      freeGoods: "+2 Free E-Books",
      total: "1074",
      totalWithDiscount: "294",
      shippingMode: "+ FREE US SHIPPING",
    },
    {
      header: "Most Popular",
      supplyDays: "90 Days Supply",
      image: sugarDefenderSix,
      value: "59",
      saving: "360",
      freeGoods: "+2 Free E-Books",
      total: "537",
      totalWithDiscount: "177",
      shippingMode: "+ FREE US SHIPPING",
    },
  ];

  return (
    <div className="buying-cards-placeholder">
      {promotionCards.map((item) => (
        <div className="buying-card">
          <p>{item.header}</p>
          <p>{item.supplyDays}</p>
          <img src={item.image} />
          <div className="price-placeholder">
            <h1>$ {item.value}</h1>
            <p>Per Bottle</p>
          </div>
          <p>
            you save $ {item.saving} <br />
            <br /> {item.freeGoods.length >= 2 && <span>{item.freeGoods}</span>}
          </p>

          <a
            href="https://0a636ttnwern00yl-bohh3iu0r.hop.clickbank.net/?&traffic_source=google&traffic_type=paid"
            target="_blank"
          >
            Order Now!
          </a>
          <p>60 Day Money-Back Guarantee</p>
          <img src={cardImage} />
          <p>
            TOTAL: $<span> {item.total}</span> {item.totalWithDiscount}
          </p>
          <p>{item.shippingMode}</p>
          <img id="money-back-seal" src={moneyBackTrigger} alt="money-back-seal"/>
          <img id="signature-card" src={signature} alt="signature"/>
        </div>
      ))}
    </div>
  );
}

export default BuyingCards;
