//styles
import "./Index.scss";

function Card(props) {
  return (
    <div className="card">
      <div className="question-placeholder">
        <h1>{`${props.question}`}</h1>
      </div>
      <p>{`${props.answer}`}</p>
    </div>
  );
}

export default Card;
