//styles
import "./Index.scss";
//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot } from "@fortawesome/free-solid-svg-icons";
//assets
import siteLogo from "../../assets/Images/site-logo.png";
import siteLogoBgGrey from "../../assets/Images/site-logo-background-grey.png";
import siteLogoWords from "../../assets/Images/site-logo-words.png";
import sugarDefenderImage from "../../assets/Images/1bottle-fruits.png";
import { Link } from "react-scroll";

function Home() {
  // list of questions to use in the about section
  const questions = [
    {
      question: "Who are we?",
      answer:
        "A group of analysts specialized in products from different niches who are committed to bringing you the best options on the market.",
    },
    {
      question: "What is our goal?",
      answer:
        "Help you find your best version through high quality products available on the market.",
    },
  ];

  // list of priducts
  const products = [
    {
      category: "Health & Fitness - Dietary Supplements",
      name: "Sugar Defender",
      image: sugarDefenderImage,
      description:"Sugar Defender has helped thousands safely assist their blood sugar and drop pounds. Quickly, easily and from home.",
      link: "/SugarDefender",
    },
  ];

  return (
    <>
      <div className="home-body">
        {/* NavBar */}
        <div className="nav-bar">
          <img src={siteLogo} alt="site-logo" />
          <div className="nav-bar-list-placeholder">
            <ul>
              <li>
                <Link to="about">about</Link>
              </li>
              <li>
                <Link to="products-list-placeholder">products</Link>
              </li>
            </ul>
          </div>
        </div>
        {/* About */}
        <div className="about">
          <img src={siteLogoBgGrey} alt="site-logo" />
          <img src={siteLogoWords} alt="site-logo-words" />
          <h1></h1>
          <div className="brefing-about-the-store-placeholder">
            {questions.map((item) => (
              <div className="about-question">
                <h1>{item.question}</h1>
                <h2>{item.answer}</h2>
              </div>
            ))}
          </div>
        </div>
        {/* About */}
        <div className="products-list-placeholder">
          <h1>Meet our products</h1>
          {products.map((item) => (
            <div className="product-card">
              <div className="text-area">
                <h1>{item.name}</h1>
                <p>{item.description}</p>
                <a href={item.link} target="_blank">
                  Order Now
                </a>
              </div>
              <img src={item.image} />
            </div>
          ))}
        </div>
        {/* About */}
        <div className="footer">
          <h1>Thanks for visiting! Enjoy our products.</h1>
          <a>
            <FontAwesomeIcon icon={faMugHot} color="white" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Home;
