//styles
import "./Index.scss";
//modules
import BuyingCards from "../BuyingCards/Index.js";
//assets
import stDaysGuarantee from "../../../assets/Images/money-back.png";
import stars from "../../../assets/Images/stars.png";
import signature from "../../../assets/Images/signature.png";
//external components
import { faArrowDown, faCartFlatbed, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LandingContainer() {
  return (
    <>
      <div className="mobile-header">
        <p>Improve your lifestyle with the best products on the market</p>
        <div className="text-zone">
          <h1>more</h1>
          <h1>offers!</h1>
        </div>
        <FontAwesomeIcon icon={faArrowDown} color="black" />
        <a
          href="/"
          target="_blank"
        >
          <FontAwesomeIcon icon={faShoppingBasket} color="#1d1d1d" />
        </a>
      </div>
      <div className="landing-container">
        <div className="side-banner-landing-container">
          <img src={stDaysGuarantee} alt="60-days-guarantee" />
          <div className="signature">
            <img src={signature} alt="signature" />
            <img src={stars} alt="starts" />
          </div>
        </div>
        <div className="center-banner-landing-container">
          <h1>The Best Offers Just For You!</h1>
          <BuyingCards />
        </div>
        <div className="side-banner-landing-container">
          <p>Improve your lifestyle with the best products on the market</p>
          <div className="text-zone">
            <h1>more</h1>
            <h1>offers!</h1>
            <FontAwesomeIcon icon={faArrowDown} color="black" />
          </div>

          <a
            href="/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faShoppingBasket} color="white" />{" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default LandingContainer;
