//styles
import "./Index.scss";
//assets
import africanMango from "../../../assets/Images/i-african-mango.jpg";
import chromium from "../../../assets/Images/i-chromium-picolinate.jpg";
import coleus from "../../../assets/Images/i-coleus.jpg";
import eleuthero from "../../../assets/Images/i-eleuthero.jpg";
import ginseng from "../../../assets/Images/i-ginseng-root.jpg";
import guarana from "../../../assets/Images/i-guarana.jpg";
import gymnema from "../../../assets/Images/i-gymnema.jpg";
import macaRoot from "../../../assets/Images/i-maca-root.jpg";

function IngredientsCards() {
  const igredients = [
    {
      header: "Eleuthero",
      disclaimer: "Increases Energy",
      image: eleuthero,
    },
    {
      header: "Coleus",
      disclaimer: "Fat Burning Aid",
      image: coleus,
    },
    {
      header: "Maca Root",
      disclaimer: "Boosts Your Energy",
      image: macaRoot,
    },
    {
      header: "African Mango",
      disclaimer: "Fat Burning Agent",
      image: africanMango,
    },
    {
      header: "Guarana",
      disclaimer: "Stimulates Your Metabolism",
      image: guarana,
    },
    {
      header: "Gymnema",
      disclaimer: "Supports Healthy Heart & Blood Sugar",
      image: gymnema,
    },
    {
      header: "Ginseng",
      disclaimer: "Supports Healthy Blood Glucose",
      image: ginseng,
    },
    {
      header: "Chromium",
      disclaimer: "Controls Blood Glucose Levels",
      image: chromium,
    },
  ];

  return (
      igredients.map((item) => (
        <div className="ingredient-card">
          <div className="ingredient-image-placeholder">
            <img src={item.image} alt={item.header}/>
          </div>
          <h1>{item.header}</h1>
          <p>{item.disclaimer}</p>
        </div>
      ))
  );
}

export default IngredientsCards;
